var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetFrame", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.$t("permission.milestones")) + " ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.canView("PROJECT", ["TASK"])
              ? _c("div", { staticClass: "center-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "entity_selector.error.insufficient_permission_to_show_data"
                        )
                      ) +
                      " "
                  ),
                ])
              : !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : [
                  _vm.loaded
                    ? _c("MilestoneList", {
                        staticClass: "staff-list",
                        attrs: {
                          milestones: _vm.milestones,
                          columns: _vm.profile ? _vm.profile.columns : null,
                        },
                        on: {
                          columnsUpdated: _vm.columnsUpdated,
                          editTask: _vm.editTask,
                        },
                      })
                    : _vm._e(),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }