import { render, staticRenderFns } from "./ProjectMilestonesWidget.vue?vue&type=template&id=00ae86a0&scoped=true"
import script from "./ProjectMilestonesWidget.vue?vue&type=script&lang=js"
export * from "./ProjectMilestonesWidget.vue?vue&type=script&lang=js"
import style0 from "./ProjectMilestonesWidget.vue?vue&type=style&index=0&id=00ae86a0&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00ae86a0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/janustest/.jenkins/workspace/05_Client_Vue_Release_Pipeline/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00ae86a0')) {
      api.createRecord('00ae86a0', component.options)
    } else {
      api.reload('00ae86a0', component.options)
    }
    module.hot.accept("./ProjectMilestonesWidget.vue?vue&type=template&id=00ae86a0&scoped=true", function () {
      api.rerender('00ae86a0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dashboard/Widget/ProjectMilestonesWidget.vue"
export default component.exports